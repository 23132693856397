/* eslint react-hooks/exhaustive-deps: "warn" */

import { forwardRef, ForwardedRef, useCallback, useRef, useState, useEffect, useMemo } from 'react';

import cn from 'classnames';

import { Link } from 'libs/router/Link';
import { clearTimeoutIfExists } from 'libs/node';

import type { ButtonFeatures } from 'ui/atoms/Button/Button.types';
import { LoaderSpin } from 'ui/atoms/LoaderSpin/LoaderSpin';

export const Button = forwardRef(
  (
    {
      className = '',
      id,
      type = 'primary',
      loadingDelay = 1000,
      loading: loadingProps,
      size = 64,
      width,
      children,
      ...props
    }: ButtonFeatures,
    ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const loadingTimer = useRef<NodeJS.Timeout>();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
      return () => {
        clearTimeoutIfExists(loadingTimer.current);
      };
    }, []);

    const onClickHandler = useCallback(() => {
      if (loadingDelay > 0) {
        setLoading(true);

        clearTimeoutIfExists(loadingTimer.current);
        loadingTimer.current = setTimeout(() => {
          setLoading(false);
        }, loadingDelay);
      }

      if (typeof props.onClick === 'function') {
        return props.onClick();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingDelay, props.onClick]);

    const Component = useMemo(() => {
      if ('href' in props) {
        return 'a';
      }

      if ('route' in props) {
        return Link;
      }

      return 'button';
    }, [props]);

    const additionalProps =
      'route' in props
        ? {
            route: props.route,
            ariaLabel: props.ariaLabel,
            query: props.query,
          }
        : 'href' in props
        ? {
            rel: props.rel,
            href: props.href,
            target: props.target,
          }
        : {
            type: props.htmlType,
            onClick: props.onClick,
            disabled: props.disabled || loadingProps,
          };

    return (
      <Component
        id={id || undefined}
        ref={ref as ForwardedRef<HTMLButtonElement & HTMLAnchorElement>}
        className={cn(
          'button',
          `button-type-${type}`,
          props.disabled && 'disabled',
          props.hideDisabled && 'not-allowed',
          (loading || loadingProps) && 'loading',
          `size-${size}`,
          className,
        )}
        {...additionalProps}
        onClick={onClickHandler}
        style={width ? { width } : undefined}
      >
        {(loading || loadingProps) && <LoaderSpin />}

        {children}
      </Component>
    );
  },
);
